<template>
    <cms-block v-bind="$props" @block-selected="selectBlock" @clicked="$emit(`clicked`, $event)" :style="blockStyle" :class="{ 'interactive': interactive || currentLink }"
               :block-component="CmsLink" :block-component-params="{link: currentLink}">
        <b-row no-gutters>
            <b-col v-if="block.image.active" :cols="cols" :order="block.mediaProxy.layout === 'row-right' ? 2 : 0" class="flex" :style="imageContainerStyle">
                <cms-component-image comp-id="image" :component="block.image" v-bind="componentProps"/>
            </b-col>
            <b-col v-if="block.title.isVisible() || block.subtitle.isVisible() || block.description.isVisible() || block.button.isVisible()" :cols="cols" class="flex" :class="{ 'mt': isMobileLayoutOrPreview || block.mediaProxy.layout === 'default'}">
                <cms-component-text comp-id="title" :component="block.title" v-bind="componentProps"/>
                <cms-component-text comp-id="subtitle" :component="block.subtitle" v-bind="componentProps"/>
                <cms-component-text comp-id="description" :component="block.description" v-bind="componentProps"/>
                <cms-component-button comp-id="button" :component="block.button" v-bind="componentProps"/>
            </b-col>
        </b-row>
        <slot></slot>
    </cms-block>
</template>

<script>
import CmsLink from "@/components/cms/cms-link.vue";
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentButton from "@/components/editor/rendering/components/cms-component-button.vue";
import CmsComponentImage from "@/components/editor/rendering/components/cms-component-image.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-list-item #hot-reload-debug
export default {
    name: `cms-block-list-item`,
    components: {CmsComponentButton, CmsComponentText, CmsComponentImage, CmsBlock},
    extends: CmsBlock,
    props: {
        card: {type: Boolean},
        interactive: {type: Boolean}
    },
    data() {
        return {
            CmsLink
        }
    },
    computed: {
        blockStyle() {
            let style = {
                "--container-radius": this.block.borderRadius
            };
            return style;
        },
        imageContainerStyle() {
            let style = {};
            if (this.block.image.maxWidth && !this.isMobileLayoutOrPreview && this.block.mediaProxy.layout !== `default`) {
                style.maxWidth = `calc(${this.block.image.maxWidth}px + var(--block-spacing) * 2)`;
            }
            return style;
        },
        currentLink() {
            let link;
            if (this.block.link.useBinding && this.boundItem && this.block.link.linkField) {
                link = this.boundItem.getField(this.block.link.linkField);
            }
            if (!link) {
                link = this.block.link.link;
            }
            return link;
        },
        cols() {
            if (this.isMobileLayoutOrPreview) {
                return 12;
            }
            return this.block.mediaProxy.layout === `default` ? 12 : ``;
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-block-list-item {
    &.interactive {
        cursor: pointer;

        &:hover {
            filter: brightness(1.2);
        }

        &:active {
            filter: brightness(1.3);
        }
    }
}
</style>
